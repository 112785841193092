import { FloatingPanelFooter } from '../../shared/components/FloatingPanelFooter';
import { TranslationService } from '../../../services/TranslationService';
import { AutocompleteGroup, DatePickerEditor, TextAreaBigEditor, TextEditor, TextEditorNumber } from '../../shared/components/Editors';
import TextInput from './TextInput';
import { DataType } from '../../../services/CompanyService';
import Dropdown from '../../shared/components/Dropdown';
import { ifOldFormat } from '../../client/invoice/InvoiceMassiveChangesFieldEdit';
import { ValidationMessage } from '../../shared/RequieredManager';

export const SendMessageCompose = ({ dataFields, ignoreFields }: any) => {
  const dataFieldsFormated = dataFields.Dependencies;
  const show = !ignoreFields && dataFieldsFormated.length > 0;
  return (
    <>
      <div className="tabBody">
        {show &&
          dataFieldsFormated
            .map((fieldFormated: any) => <FieldEdit
              key={fieldFormated.id}
              field={fieldFormated}
              onChange={() => console.log(fieldFormated)}
            />)}

        {show && <hr />}

        <div className={"d-flex input-column mb-3"}>
          <label className="form-label">{TranslationService.translate.Mail}</label>
          <TextInput />
        </div>


        <div className={"d-flex input-column mb-3"}>
          <label className="form-label">{TranslationService.translate.Message}</label>
          <TextAreaBigEditor extraStyle={{ height: 200 }} onChange={() => { }} />
        </div>

        Adjuntar archivo

      </div>
      <FloatingPanelFooter>
        <button className='btn btn-primary me-3'>
          {TranslationService.translate.Send}
        </button>
      </FloatingPanelFooter>
    </>
  )
}


const FieldEdit = ({ field, onChange, }: any) => {
  const { translate } = TranslationService;
  let editor = undefined;

  switch (field.Type) {
    case DataType.List:
      const drpItems = field.ListItems.map((x: any) => ({ value: x.AdditionalDefinitionItemID.toString(), text: x.Value }));
      editor = <Dropdown optionLabel={translate.Select} onChange={onChange} items={drpItems} />;
      break;
    case DataType.Date:
      let date = undefined;
      let val = date ? ifOldFormat(date) : undefined;
      editor = <DatePickerEditor onChange={onChange} defaultValue={val} />;
      break;
    case DataType.Number:
    case DataType.Currency:
      editor = <TextEditorNumber onChange={onChange} />;
      break;
    case DataType.Group:
      editor = <AutocompleteGroup onChange={x => onChange(x?.value)} clientId={'-1'} />;
      break;
    case DataType.Phone:
    case DataType.Text:
    case DataType.Link:
    default:
      editor = <div><TextEditor onChange={onChange} /></div>;
      break;
  }

  return (
    <div className={"d-flex input-column mb-3"}>
      <label className="form-label">
        {field.Title}
      </label>
      {editor}
      <ValidationMessage onChange={onChange} />
    </div>
  );
}
